import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { crmMenu, dashboardMenu, webMenu, empManageMenu } from "@/data/sidebar";
import { PlusIcon } from "lucide-react";

interface SubMenuItem {
  menu_title: string;
  menu_link: string;
  icon: JSX.Element;
}

interface MenuItemProps {
  menu_title: string;
  menu_link?: string;
  icon?: JSX.Element;
  submenu?: SubMenuItem[];
}

const SideMenu = () => {
  return (
    <div className="w-64 h-screen sticky top-0 p-3">
      {dashboardMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
      {webMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
      {crmMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
      {empManageMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
    </div>
  );
};

const MenuItemComponent = ({
  icon,
  menu_title,
  menu_link,
  submenu,
}: MenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubMenu = submenu && submenu.length > 0;

  const toggleSubMenu = () => setIsOpen((prev) => !prev);

  return hasSubMenu ? (
    <Collapsible open={isOpen}>
      <CollapsibleTrigger asChild onClick={toggleSubMenu}>
        <li className="list-none w-full flex items-center gap-2 px-3 py-2 hover:bg-gray-100 hover:text-primary rounded-md cursor-pointer">
          {icon}
          <span>{menu_title}</span>
          <span
            className={`ml-auto transition-transform duration-200 ${
              isOpen ? "rotate-45" : ""
            }`}
          >
            <PlusIcon className="w-4" />
          </span>
        </li>
        {/* <NavLink
          to={"#"}
          className={({ isActive }) =>
            `w-full flex items-center gap-2 px-3 py-2 hover:bg-primary hover:text-white rounded-md cursor-pointer ${
              isActive ? "bg-primary text-white" : ""
            }`
          }
        >
          {icon}
          <span>{menu_title}</span>
          <span
            className={`ml-auto transition-transform duration-200 ${
              isOpen ? "rotate-45" : ""
            }`}
          >
            <PlusIcon className="w-4" />
          </span>
        </NavLink> */}
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="pl-4">
          {submenu.map((submenuItem, index) => (
            <SubMenu key={index} {...submenuItem} />
          ))}
        </div>
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <NavLink
      to={menu_link || "#"}
      className={({ isActive }) =>
        `flex items-center gap-2 px-3 py-2 hover:bg-gray-100 hover:text-primary rounded-md ${
          isActive
            ? "bg-primary text-white hover:text-primary hover:bg-gray-100"
            : "hover:bg-gray-100 hover:text-primary"
        }`
      }
    >
      {icon}
      <span>{menu_title}</span>
    </NavLink>
  );
};

const SubMenu = ({ menu_link, menu_title, icon }: SubMenuItem) => {
  return (
    <NavLink
      to={menu_link || "#"}
      className={({ isActive }) =>
        `w-full flex flex-row items-center justify-between rounded-md px-3 py-2 hover:text-primary transition duration-200 ${
          isActive
            ? "bg-primary text-white hover:text-primary hover:bg-gray-100"
            : ""
        }`
      }
    >
      <span className="flex flex-row items-center gap-2">
        {icon}
        {menu_title}
      </span>
    </NavLink>
  );
};

export default SideMenu;
