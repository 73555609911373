import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import { DebounceInput } from "react-debounce-input";

import { usePageFilters } from "@/lib/store";
import TitleAndBC from "@/components/Misc/TitleAndBC";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { ControlledPagination } from "@/components/controlled-pagination";
import { EmployeeActivityCols } from "./EmployeeActivityCols";

const EmployeeActivity = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { pageFilters } = usePageFilters();
  const callsPageFilters = pageFilters["calls"];

  //Selected Rows State
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  //Filters Start
  const [EmpIds, setEmpIds] = useState<string[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const columns = useMemo(() => EmployeeActivityCols, [EmployeeActivityCols]);
  //Filters End

  //Pagination State
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  //Pagination End

  const queryParams = useMemo(
    () => ({
      currentPage: String(pageIndex), // Convert number to string
      pageSize: String(pageSize), // Convert number to string
      search: globalFilter,
      empIds: EmpIds,
      sorting: sorting.map(({ id, desc }) => ({ id, desc })),
    }),
    [pageIndex, pageSize, sorting, EmpIds, globalFilter]
  );

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      "adminCallNotes",
      pageIndex,
      pageSize,
      globalFilter,
      EmpIds,
      sorting,
    ],
    queryFn: async () => {
      try {
        const response = await axiosInstance.post(
          "/admin/employee-activity/paginated",
          queryParams
        );
        return response.data; // Return the data directly
      } catch (err) {
        throw err; // Re-throw the error to be handled by useQuery
      }
    },
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (globalFilter) {
      // Update pagination state with pageIndex 0 and keep the same pageSize
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 0,
      }));
    }
  }, [globalFilter]);

  useEffect(() => {
    if (callsPageFilters) {
      setEmpIds(callsPageFilters.employeesFilters);
    }
  }, [callsPageFilters]);

  useEffect(() => {
    console.log("selected rows", rowSelection);
  }, [rowSelection]);

  const table = useReactTable({
    data: data?.rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: data?.pageCount || 0,
    state: {
      pagination,
      rowSelection,
      sorting,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
  });

  if (isPending) return <p>Fetching Data...</p>;
  if (isError) return <p>{error.message}</p>;

  return (
    <div>
      <TitleAndBC page_title="Employee Activity" />
      <div className="flex items-center py-4 gap-2">
        <DebounceInput
          className="p-2 border"
          debounceTimeout={500}
          placeholder="Search..."
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(String(event.target.value))}
        />
      </div>
      <div className="card bg-white">
        <div className="card-body">
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() ? "selected" : null}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeActivity;
