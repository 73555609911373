import { create } from "zustand";

type TLeadStatusFilterState = {
  statusesByPage: Record<string, string[]>;
  selectStatus: (pageKey: string, employee_ids: string[]) => void;
  clearStatus: (pageKey: string) => void;
  getStatuses: (pageKey: string) => string[]; // Add a getter function
};

export const useLeadStatusFilter = create<TLeadStatusFilterState>(
  (set, get) => ({
    statusesByPage: {},
    selectStatus: (pageKey, employeeIds) =>
      set((state) => ({
        statusesByPage: {
          ...state.statusesByPage,
          [pageKey]: Array.from(new Set(employeeIds)),
        },
      })),
    clearStatus: (pageKey) =>
      set((state) => ({
        statusesByPage: {
          ...state.statusesByPage,
          [pageKey]: [],
        },
      })),
    getStatuses: (pageKey) => get().statusesByPage[pageKey] || [],
  })
);
