import EmployeeWiseCallsReport from "@/components/Charts/EmployeeWiseCallsReport";
import EmployeeWiseRemindersReport from "@/components/Charts/EmployeeWiseRemindersReport";
import EmployeeWiseUsersReport from "@/components/Charts/EmployeeWiseUsersReport";
import StatusWiseUsersReport from "@/components/Charts/StatusWiseUsersReport";
import TitleAndBC from "@/components/Misc/TitleAndBC";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const AllReports = () => {
  return (
    <>
      <TitleAndBC page_title="All Reports" />
      <div className="grid xl:grid-cols-2 gap-8 my-4">
        <Card>
          <CardHeader>
            <CardTitle>Employee Wise Users Report</CardTitle>
          </CardHeader>
          <CardContent>
            <EmployeeWiseUsersReport />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Staus Wise Users Report</CardTitle>
          </CardHeader>
          <CardContent>
            <StatusWiseUsersReport />
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Calls Report</CardTitle>
          </CardHeader>
          <CardContent>
            <EmployeeWiseCallsReport />
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Reminders Report</CardTitle>
          </CardHeader>
          <CardContent>
            <EmployeeWiseRemindersReport />
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default AllReports;
