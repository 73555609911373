import { LeadStatusFilter } from "@/components/Forms/Lead/LeadStatusFilter";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { XCircleIcon, FilterIcon } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useEffect, useState } from "react";
import { LeadManagerFilter } from "@/components/Forms/Lead/LeadManagerFilter";
import { useLeadManagerFilters } from "@/store/leadManagerFilters.store";
import { useLeadStatusFilter } from "@/store/leadStatusFilter.store";

const UsersPageFilters = () => {
  // Lead Statuses
  const { getStatuses, clearStatus } = useLeadStatusFilter();
  const statuses = getStatuses("usersPage");

  // Employee Id's
  const { getEmployeeIds, clearEmployees } = useLeadManagerFilters();
  const employeeIds = getEmployeeIds("usersPage");

  const [showClearButton, setShowClearButton] = useState(false);

  useEffect(() => {
    if ((statuses && statuses.length) || (employeeIds && employeeIds.length)) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  }, [statuses, employeeIds]);

  const handleClearFilters = () => {
    clearEmployees("usersPage");
    clearStatus("usersPage");
  };

  return (
    <div className="flex flex-row flex-wrap gap-5">
      {showClearButton && (
        <Button onClick={handleClearFilters}>
          <XCircleIcon className="w-4 h-4 mr-2" />
          Clear Filters
        </Button>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={`secondary`} className="hover:text-primary">
            <FilterIcon className="w-4 h-4 mr-2" />
            Filter by Manager
            {employeeIds && employeeIds.length ? (
              <Badge className="ml-2 bg-primary">{employeeIds.length}</Badge>
            ) : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit">
          <LeadManagerFilter pageKey="usersPage" />
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={`secondary`} className="hover:text-primary">
            <FilterIcon className="w-4 h-4 mr-2" />
            Filter by Lead Status
            {statuses && statuses.length ? (
              <Badge className="ml-2 bg-primary">{statuses.length}</Badge>
            ) : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit">
          <LeadStatusFilter pageKey="usersPage" />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default UsersPageFilters;
