import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { formatDate } from "@/lib/utils";
import { MoveRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import {
  createColumnHelper,
  SortDirection,
  Column,
} from "@tanstack/react-table";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";

type TUser = {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  contact_number: string;
  lead_status: string;
  lead_manager: {
    first_name: string;
    last_name: string;
  };
  source: string;
  createdAt: string;
  updatedAt: string;
};

type TSortingComponent = {
  title: string;
  isSorted: false | SortDirection;
  column: Column<TUser, string>;
};

const SortingComponent = ({ title, isSorted, column }: TSortingComponent) => {
  return (
    <div className="flex items-center gap-2">
      {title}
      <Button
        variant={isSorted ? "blue" : "outline"}
        size="sm"
        onClick={() => column.toggleSorting()}
      >
        {isSorted === "asc" ? (
          <ArrowDown className="h-4 w-4" />
        ) : isSorted === "desc" ? (
          <ArrowUp className="h-4 w-4" />
        ) : (
          <ArrowUpDown className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
};

const columnHelper = createColumnHelper<TUser>();

export const UsersCols = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: (props) => (
      <Checkbox
        checked={props.row.getIsSelected()}
        onCheckedChange={(value) => props.row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.display({
    id: "name",
    header: "User",
    cell: (props) => {
      return (
        <Link
          to={`lead/${props.row.original._id}`}
          className="block py-2 px-3 rounded-md hover:bg-blue-400 transition duration-150 group"
        >
          <p className="font-semibold text-gray-800 group-hover:text-white">
            {props.row.original?.first_name +
              " " +
              props.row.original?.last_name}
          </p>
          <p className="text-sm text-blue-500 mt-0.5 group-hover:text-blue-200">
            {props.row.original.email}
          </p>
        </Link>
      );
    },
  }),
  columnHelper.accessor("contact_number", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Contact" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor(
    (row) => {
      return row.lead_manager
        ? `${row?.lead_manager?.first_name} ${row?.lead_manager?.last_name}`
        : "";
    },
    {
      header: "Manager",
    }
  ),
  columnHelper.accessor("lead_status", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Status" isSorted={isSorted} column={column} />
      );
    },
    cell: (info) => (
      <Button
        className={
          getStatusClass(info.getValue()) +
          " capitalize font-normal transition duration-150"
        }
      >
        {info.getValue()}
      </Button>
    ),
  }),
  columnHelper.accessor("source", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Source" isSorted={isSorted} column={column} />
      );
    },
  }),
  columnHelper.accessor("createdAt", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent
          title="Received"
          isSorted={isSorted}
          column={column}
        />
      );
    },
    cell: (props) => formatDate(props.getValue()),
  }),
  columnHelper.accessor("updatedAt", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Updated" isSorted={isSorted} column={column} />
      );
    },
    cell: (props) => formatDate(props.getValue()),
  }),
  columnHelper.accessor((row) => row._id, {
    header: "Actions",
    cell: (info) => (
      <Link to={`lead/${info.getValue()}`}>
        <MoveRightIcon className="w-5 h-5 text-primary" />
      </Link>
    ),
  }),
];

const statusClasses: Record<string, string> = {
  "New Lead": "bg-green-100 hover:bg-green-300/90 text-green-800",
  "Not Reachable": "bg-gray-200 hover:bg-gray-400/90 text-gray-800",
  "Wrong Number": "bg-yellow-200 hover:bg-yellow-400/90 text-yellow-800",
  "Call Disconnected": "bg-red-100 hover:bg-red-300/90 text-red-800",
  "Call Not Answering": "bg-orange-200 hover:bg-orange-400/90 text-orange-800",
  "Closed Lost": "bg-red-200 hover:bg-red-400/90 text-red-800",
  "Closed Junk": "bg-gray-300 hover:bg-gray-500/90 text-gray-900",
  "Interested Cold": "bg-blue-100 hover:bg-blue-300/90 text-blue-800",
  "Interested Warm": "bg-yellow-100 hover:bg-yellow-300/90 text-yellow-800",
  "Interested Hot": "bg-orange-100 hover:bg-orange-300/90 text-orange-800",
  "Registered (IER)": "bg-teal-100 hover:bg-teal-300/90 text-teal-800",
  "Full Paid": "bg-green-200 hover:bg-green-400/90 text-green-800",
  "Contact in Future": "bg-indigo-100 hover:bg-indigo-300/90 text-indigo-800",
};

const getStatusClass = (status: string): string => statusClasses[status] || "";
