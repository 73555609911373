import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import * as z from "zod";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useEffect } from "react";
import { LEAD_STATUSES } from "@/data/assessment.data";
import { useLeadStatusFilter } from "@/store/leadStatusFilter.store";

type LeadStatusFilterProps = {
  pageKey: string;
};

const FormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
});

export function LeadStatusFilter({ pageKey }: LeadStatusFilterProps) {
  const { statusesByPage, selectStatus } = useLeadStatusFilter();
  const statuses = statusesByPage[pageKey] || [];

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items: statuses && statuses.length ? statuses : [],
    },
  });

  const statusFilters = useWatch({
    control: form.control,
    name: "items",
  });

  useEffect(() => {
    if (statusFilters) {
      selectStatus(pageKey, statusFilters);
    }
  }, [statusFilters, selectStatus, pageKey]);

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="items"
        render={({ field }) => (
          <FormItem>
            <div className="mb-4">
              <FormLabel className="text-base">Lead Status</FormLabel>
              <FormDescription>Select Status to Filter Data</FormDescription>
            </div>
            {LEAD_STATUSES.map((item) => (
              <FormItem
                key={item}
                className="flex flex-row items-start space-x-3 space-y-0"
              >
                <FormControl>
                  <Checkbox
                    checked={field.value?.includes(item)}
                    onCheckedChange={(checked) => {
                      return checked
                        ? field.onChange([...field.value, item])
                        : field.onChange(
                            field.value?.filter((value) => value !== item)
                          );
                    }}
                  />
                </FormControl>
                <FormLabel className="font-normal">{item}</FormLabel>
              </FormItem>
            ))}
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
}
