import { jwtDecode } from "jwt-decode";
import { useTokenStore } from "@/store/auth.store";

type decodeTokenProps = {
  name: string;
  username: string;
  roles: string[];
};

export const useDecodeToken = (): decodeTokenProps | null => {
  const { authToken } = useTokenStore();
  let decodedToken: decodeTokenProps | null = null;
  if (authToken) {
    decodedToken = jwtDecode(authToken);
  }
  return decodedToken;
};
