import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { XCircleIcon, FilterIcon } from "lucide-react";
import { LeadManagerFilter } from "@/components/Forms/Lead/LeadManagerFilter";
import { useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { useLeadManagerFilters } from "@/store/leadManagerFilters.store";

const ReminderPageFilters = () => {
  const pageKey = "remindersPage";
  const { getEmployeeIds, clearEmployees } = useLeadManagerFilters();
  const employeeIds = getEmployeeIds(pageKey);
  const [showClearButton, setShowClearButton] = useState(false);

  useEffect(() => {
    setShowClearButton(!!employeeIds?.length);
  }, [employeeIds]);

  const handleClearFilters = () => {
    clearEmployees(pageKey);
  };

  return (
    <div className="flex flex-row flex-wrap gap-5">
      {showClearButton && (
        <Button onClick={handleClearFilters}>
          <XCircleIcon className="w-4 h-4 mr-2" />
          Clear Filters
        </Button>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="hover:text-primary hover:bg-white"
          >
            <FilterIcon className="w-4 h-4 mr-2" />
            Filter by Employees
            {employeeIds && employeeIds.length ? (
              <Badge className="ml-2 bg-primary">{employeeIds.length}</Badge>
            ) : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit">
          <LeadManagerFilter pageKey="remindersPage" />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ReminderPageFilters;
