import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import * as z from "zod";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useLeadManagerFilters } from "@/store/leadManagerFilters.store";

type LeadManagerFilterProps = {
  pageKey: string;
};

const FormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
});

type StateProps = {
  _id: string;
  first_name: string;
  last_name: string;
};

export function LeadManagerFilter({ pageKey }: LeadManagerFilterProps) {
  const axiosInstance = useAxiosAuthInstance();
  const { employeeIdsByPage, selectEmployees } = useLeadManagerFilters();
  const employeeIds = employeeIdsByPage[pageKey] || [];

  const [LeadManagers, setLeadManagers] = useState<StateProps[]>([]);

  const { data } = useQuery({
    queryKey: ["lead_managers"],
    queryFn: async () => {
      const response = await axiosInstance.get(`/admin/lead-managers`);
      return response.data;
    },
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items: employeeIds,
    },
  });

  const employeesFilters = useWatch({
    control: form.control,
    name: "items",
  });

  useEffect(() => {
    if (Array.isArray(data)) {
      setLeadManagers(data);
    }
  }, [data]);

  useEffect(() => {
    if (employeesFilters) {
      selectEmployees(pageKey, employeesFilters); // Pass pageKey to manage state by page
    }
  }, [employeesFilters, selectEmployees, pageKey]);

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="items"
        render={() => (
          <FormItem>
            <div className="mb-4">
              <FormLabel className="text-base">Employees</FormLabel>
              <FormDescription>Select Employees to Filter Data</FormDescription>
            </div>
            {LeadManagers.map((item) => (
              <FormItem
                key={item._id}
                className="flex flex-row items-start space-x-3 space-y-0"
              >
                <FormControl>
                  <Checkbox
                    checked={form.getValues("items").includes(item._id)}
                    onCheckedChange={(checked) => {
                      const currentValue = form.getValues("items") || [];
                      if (checked) {
                        form.setValue("items", [...currentValue, item._id]);
                      } else {
                        form.setValue(
                          "items",
                          currentValue.filter((value) => value !== item._id)
                        );
                      }
                    }}
                  />
                </FormControl>
                <FormLabel className="font-normal">
                  {item.first_name + " " + item.last_name}
                </FormLabel>
              </FormItem>
            ))}
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
}
