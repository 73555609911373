import "@/App.css";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useTokenStore } from "./store/auth.store";
import { isTokenExpired } from "./lib/utils";

function App() {
  const { authToken, setToken, deleteToken } = useTokenStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const storedToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const validateToken = () => {
      if (
        !storedToken ||
        isTokenExpired(storedToken) ||
        authToken !== storedToken
      ) {
        deleteToken();
        navigate("/login");
      } else {
        setToken(storedToken);
      }
      setIsLoading(false);
    };

    validateToken();
  }, [authToken, setToken, deleteToken, navigate, storedToken]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ToastContainer />
      <Outlet />
    </>
  );
}

export default App;
