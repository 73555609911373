import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
  flexRender,
  getCoreRowModel,
  GlobalFilterTableState,
} from "@tanstack/react-table";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { ChevronDownCircle, Plus } from "lucide-react";
import NewLeadForm from "@/components/Forms/Lead/NewLeadForm";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { DebounceInput } from "react-debounce-input";
import UsersPageFilters from "./UsersPageFilters";
import { UsersCols } from "./UsersCols";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { ControlledPagination } from "@/components/controlled-pagination";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import UsersBulkAction from "./UsersBulkAction";
import { useLeadManagerFilters } from "@/store/leadManagerFilters.store";
import { useLeadStatusFilter } from "@/store/leadStatusFilter.store";

const NewUsersPage = () => {
  const axiosInstance = useAxiosAuthInstance();
  const columns = useMemo(() => UsersCols, [UsersCols]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  //Filters Start
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] =
    useState<GlobalFilterTableState["globalFilter"]>(null);

  // Employee Id's
  const { getEmployeeIds } = useLeadManagerFilters();
  const employeeIds = getEmployeeIds("usersPage");
  // Statuses
  const { getStatuses } = useLeadStatusFilter();
  const statuses = getStatuses("usersPage");

  const queryParams = useMemo(
    () => ({
      currentPage: pagination.pageIndex, // Convert number to string
      pageSize: pagination.pageSize, // Convert number to string
      sorting: sorting.map(({ id, desc }) => ({ id, desc })),
      empIds: employeeIds,
      statuses,
      search: globalFilter,
    }),
    [
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      employeeIds,
      statuses,
      globalFilter,
    ]
  );

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      "users",
      pagination.pageIndex,
      pagination.pageSize,
      globalFilter,
      employeeIds,
      statuses,
      sorting,
    ],
    queryFn: async () => {
      try {
        const response = await axiosInstance.post(
          "/admin/users/paginated",
          queryParams
        );
        return response.data; // Return the data directly
      } catch (err) {
        throw err; // Re-throw the error to be handled by useQuery
      }
    },
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    data: data?.rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: data?.pageCount || 0,
    state: {
      pagination,
      rowSelection,
      sorting,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getRowId: (row: any) => row._id,
    rowCount: data?.totalRecords || 0,
  });

  if (isPending) return <p>Loading...</p>;
  if (isError) return <p>{error.message}</p>;

  return (
    <div className="flex flex-col gap-5">
      <NewUserDialog />

      <div className="flex flex-row justify-between gap-5 rounded-md p-5 bg-white">
        <DebounceInput
          className="p-2 border border-slate-800 rounded-md"
          debounceTimeout={500}
          placeholder="Search..."
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(String(event.target.value))}
        />
        <div className="flex justify-end items-center gap-4">
          <BulkActionsHandler selectedRecords={Object.keys(rowSelection)} />
          <UsersPageFilters />
        </div>
      </div>

      <div className="card bg-white">
        <div className="card-body">
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() ? "selected" : null}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};

const NewUserDialog = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-4 ml-auto">
      <Dialog>
        <DialogTrigger asChild>
          <Button className="bg-primary hover:bg-violet-500">
            <Plus className="mr-2 w-4 h-4" /> Add New User
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-sm">
          <DialogHeader>
            <DialogTitle>New User</DialogTitle>
          </DialogHeader>
          <NewLeadForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const BulkActionsHandler = ({
  selectedRecords,
}: {
  selectedRecords: string[];
}) => {
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [dialogView, setDialogView] = useState<JSX.Element | null>(null);

  useEffect(() => {
    switch (selectedAction) {
      case "assign":
        setDialogView(<UsersBulkAction selectedRecords={selectedRecords} />);
        break;
      case "export":
        setDialogView(<LeadsExport />);
        break;
      case "spam":
        console.log("Spam Leads");
        setDialogView(null);
        break;
      case "delete":
        console.log("Delete Leads");
        setDialogView(null);
        break;
      default:
        setDialogView(null);
        break;
    }
  }, [selectedAction, selectedRecords]);

  if (!selectedRecords.length) return;

  return (
    <div className="flex flex-row justify-start items-center gap-4">
      <p>{selectedAction}</p>
      <p className="text-gray-500 text-sm">
        {selectedRecords.length} Records Selected
      </p>
      <div>
        <Dialog>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant={`secondary`}>
                Lead Actions
                <ChevronDownCircle className="w-4 h-4 ml-2" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => setSelectedAction("assign")}>
                  Assign or Change Status
                </DropdownMenuItem>
              </DialogTrigger>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => setSelectedAction("export")}>
                  Export
                </DropdownMenuItem>
              </DialogTrigger>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => setSelectedAction("spam")}>
                  Mark as Spam
                </DropdownMenuItem>
              </DialogTrigger>
              <DialogTrigger asChild>
                <DropdownMenuItem onClick={() => setSelectedAction("delete")}>
                  Delete
                </DropdownMenuItem>
              </DialogTrigger>
            </DropdownMenuContent>
          </DropdownMenu>
          <DialogContent
            onInteractOutside={(e) => e.preventDefault()}
            onEscapeKeyDown={(e) => e.preventDefault()}
          >
            <DialogHeader>
              <DialogTitle>Assign or Change Lead Status</DialogTitle>
              <DialogDescription>
                Select Employee and Lead status to assign and change status
                simultaneously or do individual actions.
              </DialogDescription>
            </DialogHeader>
            {dialogView}
            <DialogFooter className="sm:justify-start">
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Close
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const LeadsExport = () => {
  // Export Logic
  return (
    <div>
      <Button>Export to Excel</Button>
    </div>
  );
};

export default NewUsersPage;
