import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";

type EmployeeActivity = {
  _id: string;
  title: string;
  event: string;
  resource: string;
  properties: string;
  causer: {
    first_name: string;
    last_name: string;
  };
  createdAt: string;
};

const columnHelper = createColumnHelper<EmployeeActivity>();

export const EmployeeActivityCols = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: (props) => (
      <Checkbox
        checked={props.row.getIsSelected()}
        onCheckedChange={(value) => props.row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.accessor("title", {
    header: "Title",
  }),
  columnHelper.accessor("event", {
    header: "Event",
  }),
  columnHelper.accessor("resource", {
    header: "Resource",
  }),
  columnHelper.accessor("properties", {
    header: "Properties",
  }),
  columnHelper.accessor("causer", {
    header: "Causer",
    cell: (props) => {
      const firstName = props.row.original.causer?.first_name || "";
      const lastName = props.row.original.causer?.last_name || "";
      return `${firstName} ${lastName}`.trim();
    },
  }),
  columnHelper.accessor("createdAt", {
    header: "Added On",
    cell: (props) => formatDate(props.getValue()),
  }),
];
