import { create } from "zustand";

// export interface CountState {
//   count: number;
//   increaseCount: () => void;
//   decreaseCount: () => void;
// }

// const useStore = create<CountState>()((set) => ({
//   count: 0,
//   increaseCount: () => set((state) => ({ count: state.count + 1 })),
//   decreaseCount: () => set((state) => ({ count: state.count - 1 })),
// }));

interface IDialog {
  isCreateDialogOpen: boolean;
  isEditDialogOpen: boolean;
  changeCreateDialogState: (payload: boolean) => void;
  changeEditDialogState: (payload: boolean) => void;
}

const useStore = create<IDialog>()((set) => ({
  isCreateDialogOpen: false,
  isEditDialogOpen: false,
  changeCreateDialogState: (payload) =>
    set(() => ({
      isCreateDialogOpen: payload,
    })),
  changeEditDialogState: (payload) =>
    set(() => ({
      isEditDialogOpen: payload,
    })),
}));

interface PageFiltersState {
  pageFilters: Record<string, FilterState>;
  setEmployeesFilters: (pageName: string, employeeIds: string[]) => void;
  setStatusFilters: (pageName: string, statuses: string[]) => void;
}

interface FilterState {
  employeesFilters: string[];
  statusFilters: string[];
}

export const usePageFilters = create<PageFiltersState>((set) => ({
  pageFilters: {},
  setEmployeesFilters: (pageName, employeeIds) =>
    set((state) => ({
      pageFilters: {
        ...state.pageFilters,
        [pageName]: {
          ...state.pageFilters[pageName],
          employeesFilters: employeeIds,
        },
      },
    })),
  setStatusFilters: (pageName, statuses) =>
    set((state) => ({
      pageFilters: {
        ...state.pageFilters,
        [pageName]: {
          ...state.pageFilters[pageName],
          statusFilters: statuses,
        },
      },
    })),
}));

export default useStore;
