import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import React from "react";
import useStore from "@/lib/store";
import { Separator } from "../ui/separator";

export const LabelAndValue = ({
  label,
  value,
}: {
  label: string;
  value: any;
}) => {
  return (
    <div className="flex flex-row gap-4 my-2">
      <p className="text-gray-500">{label}</p> :<p>{value}</p>
    </div>
  );
};

type TModalComponent = {
  dialog_title: string;
  dialog_desc: string;
  children: React.ReactNode;
};

export const FormCreateDialogComponent = ({
  dialog_title,
  dialog_desc,
  children,
}: TModalComponent) => {
  const { isCreateDialogOpen, changeCreateDialogState } = useStore();
  return (
    <Dialog open={isCreateDialogOpen} onOpenChange={changeCreateDialogState}>
      <DialogContent
        className="max-w-2xl"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>{dialog_title}</DialogTitle>
          <DialogDescription>{dialog_desc}</DialogDescription>
          <Separator />
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
};
