import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { NavLink } from "react-router-dom";

const TestPage = () => {
  return (
    <div>
      <DropDownMenu />
    </div>
  );
};

const DropDownMenu = () => {
  return (
    <Collapsible>
      <CollapsibleTrigger asChild>
        <NavLink to="#" className="list-none">
          Can I use this in my project?
        </NavLink>
      </CollapsibleTrigger>
      <CollapsibleContent className="p-2 bg-base-100">
        <li className="p-2 list-none hover:shadow-md hover:rounded-md hover:cursor-pointer">
          Daily Reports
        </li>
        <li className="p-2 list-none hover:shadow-md hover:rounded-md hover:cursor-pointer">
          Weekly Reports
        </li>
        <li className="p-2 list-none hover:shadow-md hover:rounded-md hover:cursor-pointer">
          Monthly Reports
        </li>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default TestPage;
