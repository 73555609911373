import App from "@/App";
import GuestLayout from "@/layouts/GuestLayout";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import Login from "@/pages/auth/Login";
import Calls from "@/pages/calls/Calls";
import EmpManage from "@/pages/employees/emp-manage/EmpManage";
import Employees from "@/pages/employees/emp-manage/Employees";
import ChannelLeads from "@/pages/sources/ChannelLeads";
import Reminders from "@/pages/reminders/Reminders";
import CrmLeadsRoutes from "@/routes/crmleads.routes";
import { Outlet } from "react-router-dom";
import Payments from "@/pages/payments/Payments";
import TimeTracker from "@/pages/employees/time-tracker/TimeTracker";
import Settings from "@/pages/Settings";
import DashboardPage from "@/pages/dashboard/Index";
import WebLeadsDataTable from "@/pages/sources/WebLeadsDataTable";
import EliLeadsDataTable from "@/pages/sources/EliLeadsDataTable";
import ResetPassword from "@/pages/auth/ResetPassword";
import SuccessPage from "@/pages/auth/SuccessPage";
import EmployeeActivity from "@/pages/employees/employee-activity/EmployeeActivity";
import NewDashboardLayout from "@/layouts/NewDashboardLayout";
import TestPage from "@/pages/TestPage";
import AllReports from "@/pages/reports/AllReports";
import LeadStatusReport from "@/pages/reports/LeadStatusReport";
import CallsReport from "@/pages/reports/CallsReport";
import RemindersReport from "@/pages/reports/RemindersReport";
import EmpActivityReport from "@/pages/reports/EmpActivityReport";

const MainRoutes = [
  {
    path: "/",
    element: <App />,
    errorElement: <p>Error Occured</p>,
    children: [
      {
        element: <GuestLayout />,
        children: [
          {
            index: true,
            element: <Login />,
          },
          { path: "login", element: <Login /> },
          { path: "forgot-password", element: <ForgotPassword /> },
          { path: "reset-password/:token", element: <ResetPassword /> },
          { path: "success", element: <SuccessPage /> },
        ],
      },
      {
        element: <NewDashboardLayout />,
        children: [
          { path: "test-page", element: <TestPage /> },
          { path: "dashboard", element: <DashboardPage /> },
          { path: "web-leads", element: <WebLeadsDataTable /> },
          { path: "eligibility-leads", element: <EliLeadsDataTable /> },
          { path: "channel-leads", element: <ChannelLeads /> },
          { path: "reminders", element: <Reminders /> },
          { path: "calls", element: <Calls /> },
          { path: "employee-activity", element: <EmployeeActivity /> },
          { path: "payments", element: <Payments /> },
          { path: "time-tracker", element: <TimeTracker /> },
          { path: "settings", element: <Settings /> },
          { path: "reports", element: <AllReports /> },
          { path: "lead-status-report", element: <LeadStatusReport /> },
          { path: "calls-report", element: <CallsReport /> },
          { path: "reminders-report", element: <RemindersReport /> },
          { path: "employee-activity-report", element: <EmpActivityReport /> },
          {
            path: "employees",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Employees />,
              },
              {
                path: "manage/:empId",
                element: <EmpManage />,
              },
            ],
          },
          ...CrmLeadsRoutes,
        ],
      },
    ],
  },
];

export default MainRoutes;
