import { Outlet } from "react-router-dom";
import NewNotificationsBar from "@/components/Misc/NewNotificationsBar";
import SideMenu from "@/components/Misc/SideMenu";

const NewDashboardLayout = () => {
  return (
    <div className="container-fluid flex bg-base-200">
      {/* Side Menu Start */}
      <SideMenu />
      {/* Side Menu End */}

      {/* Dashboard Layout Start */}
      <div className="flex-1 overflow-y-auto">
        <NewNotificationsBar />
        <div className="p-5 overflow-auto bg-slate-100 min-h-screen">
          <Outlet />
        </div>
      </div>
      {/* Dashboard Layout End */}
    </div>
  );
};

export default NewDashboardLayout;
