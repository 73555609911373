import { BellIcon, LogOutIcon } from "lucide-react";
import { Button } from "../ui/button";
import { useDecodeToken } from "@/hooks/useDecodeToken";
import { useTokenStore } from "@/store/auth.store";

const NewNotificationsBar = () => {
  const { deleteToken } = useTokenStore();
  const decodedToken = useDecodeToken();

  const handleLogout = () => {
    deleteToken();
  };

  return (
    <div className="bg-base-200 p-4 shadow-md border border-base-300">
      <div className="flex justify-between items-center gap-6">
        <div className="flex flex-col">
          <b className="text-primary text-lg">{decodedToken?.name}</b>
          <span className="text-sm text-gray-600">
            {decodedToken?.username}
          </span>
        </div>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            className="hover:bg-primary hover:text-base-100 transition duration-200 group"
          >
            <BellIcon className="text-primary w-5 h-5 group-hover:text-white" />
          </Button>
          <Button
            variant="outline"
            onClick={handleLogout}
            className="text-red-500 hover:bg-red-500 hover:text-white transition duration-200"
          >
            Signout <LogOutIcon className="w-5 h-5 ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewNotificationsBar;
