import axios from "axios";
import { axiosConfig, refreshToken } from "@/lib/utils";
import { useTokenStore } from "@/store/auth.store";

export const useAxiosAuthInstance = () => {
  const axiosInstance = axios.create(axiosConfig);
  const { setToken, authToken, deleteToken } = useTokenStore();

  // Request Interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response Interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest.retry = true;
        try {
          const newAccessToken = await refreshToken();
          deleteToken();
          setToken(newAccessToken);
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return;
          // return axiosInstance(originalRequest);
        } catch (err) {
          console.log("refresh", err);
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
