import { create } from "zustand";

type TLeadManagerFilterState = {
  employeeIdsByPage: Record<string, string[]>;
  selectEmployees: (pageKey: string, employee_ids: string[]) => void;
  clearEmployees: (pageKey: string) => void;
  getEmployeeIds: (pageKey: string) => string[]; // Add a getter function
};

export const useLeadManagerFilters = create<TLeadManagerFilterState>(
  (set, get) => ({
    employeeIdsByPage: {},
    selectEmployees: (pageKey, employeeIds) =>
      set((state) => ({
        employeeIdsByPage: {
          ...state.employeeIdsByPage,
          [pageKey]: Array.from(new Set(employeeIds)),
        },
      })),
    clearEmployees: (pageKey) =>
      set((state) => ({
        employeeIdsByPage: {
          ...state.employeeIdsByPage,
          [pageKey]: [],
        },
      })),
    getEmployeeIds: (pageKey) => get().employeeIdsByPage[pageKey] || [], // Return specific employee IDs for the given pageKey
  })
);
