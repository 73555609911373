import { create } from "zustand";

interface authTokenState {
  authToken: string;
  setToken: (token: string) => void;
  deleteToken: () => void;
}

export const useTokenStore = create<authTokenState>()((set) => ({
  authToken: localStorage.getItem("accessToken") || "",
  setToken: (token) => {
    localStorage.setItem("accessToken", token);
    set({
      authToken: token,
    });
  },
  deleteToken: () => {
    localStorage.removeItem("accessToken");
    set({
      authToken: "",
    });
  },
}));
